import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Error from '../../../Components/Error';
import PartnersService from '../../../Services/PartnersService';

function Filters({ setFilters, filters }) {
  const [email, setEmail] = useState(filters.email_address);
  const [paidOrdersOnly, setPaidOrdersOnly] = useState(filters.paid_orders_only);
  const [bookingReference, setBookingReference] = useState(filters.booking_reference);
  const [phoneNumber, setPhoneNumber] = useState(filters.phone_number);
  const [customerName, setCustomerName] = useState(filters.customer_name);
  const [dateStart, setDateStart] = useState(filters.date ? filters.date[0] : '');
  const [dateEnd, setDateEnd] = useState(filters.date ? filters.date[1] : '');
  const [status, setStatus] = useState(filters.status);
  const [partners, setPartners] = useState(filters.partners);

  const [partnersList, setPartnersList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [loadIn] = useState(true);

  const fetchPartners = async () => {
    try {
      const fetchedPartners = await PartnersService.fetchPartners();
      setPartnersList(fetchedPartners?.data?.data?.partners);
    } catch (err) {
      setError(error);
    }
  };

  const debouncedFetchPartners = debounce(() => {
    fetchPartners();
  }, 500);

  const setMultiStatus = (event) => {
    const { options } = event.target;
    const values = [];
    Array.from(options).forEach((option) => {
      if (option.selected) {
        values.push(option.value);
      }
    });

    setStatus(values);
  };

  const setMultiPartner = (event) => {
    const { options } = event.target;
    const values = [];
    Array.from(options).forEach((option) => {
      if (option.selected) {
        values.push(option.value);
      }
    });

    setPartners(values);
  };

  const search = (event) => {
    event.preventDefault();

    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    const requestedFilters = {};
    if (email) { requestedFilters.email_address = email; }
    if (paidOrdersOnly) { requestedFilters.paidOrdersOnly = true; }

    if (dateStart && dateEnd) {
      if (new Date(dateStart) > new Date(dateEnd)) {
        setError('Date Start is larger than Date End');
        return;
      }

      requestedFilters.date = [dateStart, dateEnd];
    }

    if (status) { requestedFilters.status = status; }
    if (bookingReference) { requestedFilters.booking_reference = bookingReference; }
    if (phoneNumber) { requestedFilters.phone_number = phoneNumber; }
    if (customerName) { requestedFilters.customer_name = customerName; }
    if (partners) { requestedFilters.partners = partners; }

    setFilters(requestedFilters);
  };

  useEffect(() => {
    if (filters.email_address) setEmail(filters.email);
    if (filters.paid_orders_only) setPaidOrdersOnly(filters.paid_orders_only);
    if (filters.booking_reference) setBookingReference(filters.booking_reference);
    if (filters.phone_number) setPhoneNumber(filters.phone_number);
    if (filters.customer_name) setCustomerName(filters.customer_name);
    if (filters.date) setDateStart(filters.date[0]);
    if (filters.date) setDateEnd(filters.date[1]);
    if (filters.status) setStatus(filters.status);
    if (filters.partners) setPartners(filters.partners);
  }, [filters]);

  useEffect(() => {
    debouncedFetchPartners();

    return () => {
      debouncedFetchPartners.cancel();
    };
  }, [loadIn]);

  return (
    <div className="row">
      <div className="mt-2 col-12 col-lg-6">
        <div className="card">
          <div className="card-body">
            <p className="h3">Filters</p>
            <Error error={error} />
            <form onSubmit={search}>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="paidOrdersOnly"
                  value={paidOrdersOnly}
                  onChange={(e) => setPaidOrdersOnly(e.target.value)}
                />
                <label className="form-check-label" htmlFor="paidOrdersOnly">Show paid orders only</label>
              </div>
              <div className="d-flex flex-wrap form-group-inline mt-3">
                <label className="form-check-label col-12 col-md-3" htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  placeholder="E-mail address"
                />
              </div>
              <div className="d-flex flex-wrap form-group-inline mt-3">
                <label className="form-check-label col-12 col-md-3" htmlFor="date">Date From</label>
                <input
                  className="form-control"
                  type="date"
                  value={dateStart}
                  onChange={(e) => setDateStart(e.target.value)}
                  id="dateStart"
                  placeholder="Select date"
                />

                <label className="form-check-label col-12 col-md-3" htmlFor="dateEnd">Date To</label>
                <input
                  className="form-control"
                  type="date"
                  value={dateEnd}
                  onChange={(e) => setDateEnd(e.target.value)}
                  id="dateEnd"
                  placeholder="Select date"
                />
              </div>
              <div className="d-flex flex-wrap form-group-inline mt-3">
                <label className="form-check-label  col-12 col-md-3" htmlFor="bookingReference">Booking #</label>
                <input
                  className="form-control"
                  type="input"
                  id="bookingReference"
                  value={bookingReference}
                  onChange={(e) => setBookingReference(e.target.value)}
                  placeholder="Booking reference"
                />
              </div>

              <div className="d-flex flex-wrap form-group-inline mt-3">
                <label className="form-check-label  col-12 col-md-3" htmlFor="status">Status</label>
                <select
                  multiple
                  className="form-control"
                  id="status"
                  value={status}
                  onChange={(e) => { setMultiStatus(e); }}
                >
                  <option>Confirmed</option>
                  <option>Cancelled</option>
                  <option>Request</option>
                  <option>Completed</option>
                </select>
              </div>

              <div className="d-flex flex-wrap form-group-inline mt-3">
                <label className="form-check-label  col-12 col-md-3" htmlFor="partnesr">Partner(s)</label>
                <select
                  multiple
                  className="form-control"
                  id="partners"
                  value={partners}
                  onChange={(e) => { setMultiPartner(e); }}
                >
                  <option value="">Select partner</option>
                  {partnersList.map((value) => (
                    <option
                      key={value.Id}
                      value={value.Id}
                    >
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="d-flex flex-wrap form-group-inline mt-3">
                <label className="form-check-label  col-12 col-md-3" htmlFor="phoneNumber">Phone number</label>
                <input
                  className="form-control"
                  type="input"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone number"
                />
              </div>

              <div className="d-flex flex-wrap form-group-inline mt-3">
                <label className="form-check-label  col-12 col-md-3" htmlFor="customerName">Passenger name</label>
                <input
                  className="form-control"
                  type="input"
                  id="customerName"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  placeholder="Passenger name"
                />
              </div>

              <div className="d-block mt-2">
                <button type="submit" className="btn btn-primary btn-lg">{isLoading ? <i className="fa fa-spin fa-spinner" /> : <>Search</>}</button>
              </div>

            </form>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Filters;
